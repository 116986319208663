import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  private readonly http = inject(HttpClient);

  getReportByToken(token: string): Observable<Blob> {
    return (
      this.http
        // @ts-ignore
        .post(`/docs-highlight`, { token }, { responseType: 'arraybuffer' })
        .pipe(map((data: ArrayBuffer) => new Blob([data], { type: 'application/pdf' })))
    );
  }
}
